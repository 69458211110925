<template>
    <div class="filter-box" :class="{ 'empty-results': isDisabled }">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- TYPE ID -->
            <div class="filter">
                <label>{{ $t('user.id') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.identification && currentFilters.identification.length > 0,
                        changed: currentFilters.identification && currentFilters.identification != activeFilters.identification
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.identification"
                    @keyup.enter="applyFilters()"
                />
            </div>
            <!-- TYPE NAME -->
            <div class="filter">
                <label>{{ $t('supervise.checklists.table_headers.localization') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.name && Object.values(currentFilters.name).length > 0,
                        changed: currentFilters.name && currentFilters.name != activeFilters.name
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.name"
                    @keyup.enter="applyFilters()"
                />
            </div>
        </div>

        <FilterActions :showClearFiltersButton="showClearFiltersButton" :clearFiltersCallback="resetFilters" :filterCallback="applyFilters" :changesDetected="changesDetected" />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    name: 'FilterProductAvailability',
    components: {
        Tags,
        FilterActions
    },
    props: {
        isDisabled: { type: Boolean, default: false }
    },
    data() {
        return {
            currentFilters: {},
            emptyFilters: {
                identification: '',
                name: ''
            },
            activeFilters: {},
            // Filter variables
            changesDetected: false
        }
    },
    computed: {
        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)
        }
    },
    methods: {
        applyFilters() {
            this.$overlay.loading()
            var definitiveFilters = {}

            if (this.currentFilters.identification) {
                definitiveFilters.identification = this.currentFilters.identification
            } else definitiveFilters.identification = ''

            if (this.currentFilters.name) {
                definitiveFilters.name = this.currentFilters.name
            } else definitiveFilters.name = ''

            if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                this.$emit('changes')
            } else {
                this.$emit('noChanges')
            }

            var self = this
            this.changesDetected = false

            this.$store.commit('label/setFiltersProductAvailabilityActive', this.currentFilters)
            this.$store
                .dispatch('label/loadProductAvailabilitySiteList', {
                    identification: definitiveFilters.identification,
                    name: definitiveFilters.name
                })
                .then(function () {
                    self.activeFilters = { ...self.currentFilters }
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                })
        },
        resetFilters() {
            var self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.$store.commit('label/setFiltersProductAvailabilityActive', this.currentFilters)

            this.changesDetected = false
            this.$store.dispatch('label/loadProductAvailabilitySiteList', { status: 1 }).then(function () {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
            })
        }
    },
    created() {},
    mounted() {
        var translated = this.$store.getters['label/getFiltersProductAvailabilityActive']
        for (var name in translated.status) {
            var index = translated.status[name]
            if (index.name) {
                index.name = i18n.t(index.name)
            }
        }
        this.activeFilters = {
            ...translated
        }
        this.currentFilters = {
            ...translated
        }
        this.applyFilters()
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                    this.changesDetected = true
                    this.$emit('isFiltered')
                } else {
                    this.$emit('isNoFiltered')
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>

<template>
    <div id="content" class="productAvailability">
        <template v-if="form">
            <Form :uniqueSite="isFiltered == false && Object.keys(items).length <= 1" :id="productAvailabilityID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
            </div>

            <div class="productAvailability-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <!-- sidebar filters -->
                <div class="sidebar-filters" :class="{ 'empty-results': noChanges && Object.values(items).length == 0, hidden: isFiltered == false && Object.keys(items).length <= 1, 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                    <FilerProductAvailability @isFiltered="isFiltered = true" @isNoFiltered="isFiltered = false" @noChanges="noChanges = true" @changes="noChanges = false" :isDisabled="noChanges && Object.values(items).length == 0" @resetPageDatatable="top(true)"></FilerProductAvailability>
                </div>
                <!-- table -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table v-if="items" v-model="selected" :headers="headers" fixed-header :items="Object.values(items)" sort-by :sort-asc="true" hide-default-footer :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pageCount = $event">
                        <template #item.identification="item">
                            <div @click="goToSiteProducts(item.item.id)">
                                <span class>{{ item.item.identification }}</span>
                            </div>
                        </template>
                        <template #item.name="item">
                            <div @click="goToSiteProducts(item.item.id)">
                                <span class>{{ item.item.name }}</span>
                            </div>
                        </template>
                        <template #item.state="item">
                            <div @click="goToSiteProducts(item.item.id)">
                                <span v-if="item.item.state" class>{{ item.item.state }}</span>
                                <span v-else>-</span>
                            </div>
                        </template>
                        <template #item.inactive="item">
                            <div class="status" @click="goToSiteProducts(item.item.id)">
                                <Table-statuses :text="item.item.inactive == 0 ? $t('product.product_availability.all_products') : item.item.inactive + ' ' + $tc('product.product_availability.n_inactive', item.item.inactive)" :status="item.item.inactive == 0 ? 'active' : 'inactive'"> </Table-statuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && items.length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination v-model="pagination.page" @input="top(false)" color="#2c52c4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && Object.values(items).length == 0">
                        <EmptyTable :title="$t('empty_table.users_title')" :description="$t('empty_table.products_states')"></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TableStatuses from '../components/ui/TableStatuses.vue'
import i18n from '@/i18n'
import FilerProductAvailability from '@/components/domain/productAvailability/filter'
import Form from '@/components/domain/productAvailability/form'
import EmptyTable from '@/components/ui/EmptyTable'

export default {
    components: {
        FilerProductAvailability,
        Form,
        EmptyTable,
        TableStatuses
    },
    name: 'ProductAvailability',
    data() {
        return {
            noChanges: false,
            productAvailabilityID: undefined,
            form: false,
            isFiltered: false,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('user.id'),
                    align: 'start',
                    sortable: true,
                    value: 'identification',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.checklists.table_headers.localization'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    class: 'header-table'
                },
                {
                    text: this.$t('sites.table_headers.province'),
                    align: 'start',
                    sortable: true,
                    value: 'state',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.product_availability.products_avaliable'),
                    align: 'start',
                    sortable: true,
                    value: 'inactive',
                    class: 'header-table'
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            return this.$store.getters['label/getProductAvailabilitySiteList']()
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        }
    },
    methods: {
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },
        goToSiteProducts(id) {
            this.form = true
            this.productAvailabilityID = id
        },
        closeForm() {
            this.form = false
            this.productAvailabilityID = false
            this.$router.push({
                name: 'ProductAvailability',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        load() {
            if (this.$route.params.id) {
                this.goToSiteProducts(this.$route.params.id)
            } else {
                this.form = false
                this.productAvailabilityID = false
            }
            var self = this
            var items = { ...this.$store.getters['label/getProductAvailabilitySiteList']() }
            if (Object.keys(items).length > 0) {
                if (self.isFiltered == false && Object.keys(items).length == 1) {
                    self.goToSiteProducts(Object.values(items)[0].id)
                }
            } else {
                self.$store.dispatch('label/loadProductAvailabilitySiteList', { status: 1 }).then(function (response) {
                    if (self.isFiltered == false && Object.keys(self.items).length == 1) {
                        self.goToSiteProducts(Object.values(self.items)[0].id)
                    }
                })
            }
        },
        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('ProductAvailabilitySidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('ProductAvailabilitySidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        }
    },
    watch: {
        $route: 'load'
    },
    created() {
        const productAvailability = this.$store.getters['login/getProductAvailability']
        !productAvailability ? this.$router.push('/') : this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.productAvailability {
    .productAvailability-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .sidebar-filter {
            position: relative;
            overflow: hidden;
            width: 375px;
            height: 100%;

            &.hidden {
                width: 18px;
                min-width: 18px;
                .filter-box {
                    overflow: hidden;
                    display: none;
                }
            }
        }

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .employee-img {
                    @include background($size: cover);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
                .employee-name {
                    @include text-ellipsis();
                    display: inline-block;
                    width: calc(100% - 24px);
                    padding-left: 6px;
                    color: $color-black;

                    &.pending {
                        color: $color-warning-800;
                    }
                }
                .ball-color {
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    margin-right: 3px;
                }
                .locations {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
            }
        }
        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
